exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pensieve-index-js": () => import("./../../../src/pages/pensieve/index.js" /* webpackChunkName: "component---src-pages-pensieve-index-js" */),
  "component---src-pages-pensieve-tags-js": () => import("./../../../src/pages/pensieve/tags.js" /* webpackChunkName: "component---src-pages-pensieve-tags-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-skills-index-js": () => import("./../../../src/pages/skills/index.js" /* webpackChunkName: "component---src-pages-skills-index-js" */),
  "component---src-pages-tech-index-js": () => import("./../../../src/pages/tech/index.js" /* webpackChunkName: "component---src-pages-tech-index-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-skills-js": () => import("./../../../src/templates/skills.js" /* webpackChunkName: "component---src-templates-skills-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-tech-js": () => import("./../../../src/templates/tech.js" /* webpackChunkName: "component---src-templates-tech-js" */)
}

